// general customizations

:root {
    --left-popover: 0px !important;
}

@media screen and (max-width: 1024px) {
  html, body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

body {
  min-width: $screen-md;
  font-family: "Avenir";
}

body.show-modal {
	overflow: visible;
}

// button customizations

.btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
	text-shadow: none;
    background-image: none;
}

.btn-default {
	background-color: darken($gray-lighter, 5%);
}

.btn-primary {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
}

.btn-primary:hover {
    background-color: darken($btn-primary-bg, 5%);
}

.btn-glyph-only {
    @include horizontal-padding-of-gutters(2);
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;
}

// typography customizations

.text-primary {
	color: $eup-table-header-color;
}

// dropdown customizations

ul.dropdown-menu.open-right {
    right: 0;
    left: auto;
}

// sortable table customizations

.table {
  /*IE v9 - v11 only*/
  @media screen and (min-width:0\0) {
    overflow-y: visible !important;
  }
}

.table > thead > tr > eup-sortable-th, .table > thead:first-child > tr:first-child > th
 {
    position: relative;
    display: table-cell;
    line-height: 1.42857;
    vertical-align: middle;
    border-bottom: 2px solid $table-border-color;
    border-top: 2px solid $table-border-color;
}

.table > tbody > tr:first-of-type > th, 
.table > tbody > tr:first-of-type > td {
    border-top: none;
}

.table > thead > tr > eup-sortable-th:first-of-type,
.table > tbody > tr > th:first-of-type {
    padding-left: $grid-half-gutter;
}

.table > tbody > tr {	
	
	height: $tableLineHeight;

    td, th {
        vertical-align: middle;
		/* legacy property for IE and Edge (this name is deprecated and was replaced with overflow-wrap */
		word-wrap: break-word; 
		overflow-wrap: break-word;
		hyphens: auto;
		word-break: break-all;
    }

	&.no-results th {
		font-weight: normal;
	}
}

// modal customizations
// prevents nested modals from making the screen darker
.modal-backdrop.in + .modal-backdrop.in {
    opacity: 0;
}

.input-lg {
    padding-top: 5px;
    padding-bottom: 5px;
}

.form-horizontal .control-label {
	text-align: left;
}

div.eup-bs-datepicker-container {
	display: inline-block;
	position: relative;

	input {
		padding-right: 20px;
		cursor: pointer;
		height: 30px;
	}

	&::after {
		content: '';
		position: absolute;
		right: 3px;
		top: calc(50% - 2px);
		transform: translateY(-25%);
		height: 0; width: 0;
		border: 8px solid transparent;
		border-top: 11px solid #e7e7e9;
		cursor: pointer;
		pointer-events: all;
	}

	&.selected::after {
		border-top: 11px solid #8fd7f7;
	}
}

.lab-dashboard {
    .popover {
        top: 50% !important;
        transform: translateY(-50%);
        padding: 0px;

        .arrow:after {
            border-right-color: #f4f4f5;
        }
    }

    .first-row .popover {
        top: 8% !important;
        transform: translateY(0%);

        .arrow {
            top: 18px;
        }
    }

    .second-row .popover {
        top: -100% !important;
        transform: translateY(0%);

        .arrow {
            top: 60px;
        }
    }

    .last-row .popover {
        top: 100% !important;
        transform: translateY(-103%);

        .arrow {
            top: auto;
            bottom: 5px;
        }
    }

    .before-last-row .popover {
        top: 100% !important;
        transform: translateY(-80%);

        .arrow {
            top: auto;
            bottom: 40px;
        }
    }

    .popover-content {
        padding: 0px;
        min-width: 235px;
        background: #f4f4f5;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.29);
    }

    tr:not(.active) .popover {
        display: none !important;
    }

    .popover {
        left: 700px !important;
        left: var(--left-popover) !important;
    }
}