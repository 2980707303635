@import "./variables";

// Media queries

@mixin tablet {
	@media (max-width: $screen-md) {
		@content;
	}
}

@mixin laptop {
	@media (max-height: $laptop-sm) {
		@content;
	}
}

// Page containers

@mixin page {
	@include top-margin-of-gutters(2);

	@include tablet {
		@include top-margin-of-gutters(0);
		width: 100%;
	}
}

// Margin by gutters

@mixin top-margin-of-gutters($gutter-num) {
	margin-top: $gutter-num * $grid-half-gutter !important;
}

@mixin bottom-margin-of-gutters($gutter-num) {
	margin-bottom: $gutter-num * $grid-half-gutter !important;
}

@mixin vertical-margin-of-gutters($gutter-num) {
	margin-bottom: $gutter-num * $grid-half-gutter !important;
	margin-top: $gutter-num * $grid-half-gutter !important;
}

@mixin left-margin-of-gutters($gutter-num) {
	margin-left: $gutter-num * $grid-half-gutter !important;
}

@mixin right-margin-of-gutters($gutter-num) {
	margin-right: $gutter-num * $grid-half-gutter !important;
}

@mixin horizontal-margin-of-gutters($gutter-num) {
	margin-left: $gutter-num * $grid-half-gutter !important;
	margin-right: $gutter-num * $grid-half-gutter !important;
}

@mixin top-margin-of-gutters($gutter-num) {
	margin-top: $gutter-num * $grid-half-gutter !important;
}

// Padding by gutters

@mixin top-padding-of-gutters($gutter-num) {
	padding-top: $gutter-num * $grid-half-gutter !important;
}

@mixin bottom-padding-of-gutters($gutter-num) {
	padding-bottom: $gutter-num * $grid-half-gutter !important;
}

@mixin vertical-padding-of-gutters($gutter-num) {
	padding-bottom: $gutter-num * $grid-half-gutter !important;
	padding-top: $gutter-num * $grid-half-gutter !important;
}

@mixin left-padding-of-gutters($gutter-num) {
	padding-left: $gutter-num * $grid-half-gutter !important;
}

@mixin right-padding-of-gutters($gutter-num) {
	padding-right: $gutter-num * $grid-half-gutter !important;
}

@mixin horizontal-padding-of-gutters($gutter-num) {
	padding-left: $gutter-num * $grid-half-gutter !important;
	padding-right: $gutter-num * $grid-half-gutter !important;
}

@mixin top-padding-of-gutters($gutter-num) {
	padding-top: $gutter-num * $grid-half-gutter !important;
}

@mixin text-ellipsis($number-of-lines) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	-webkit-line-clamp: $number-of-lines; // max nb lines to show
	-webkit-box-orient: vertical;
}

$menuItemHeight: 62px;

@mixin dropdown-menu($items: 5) {
	min-width: 100%;
	margin: 0;
	padding: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	max-height: $menuItemHeight*$items;
	overflow-y: auto;
	white-space: nowrap;

	li {
		line-height: $menuItemHeight;
		padding: 0 18px;
		border-bottom: 1px solid $gray;

		&:hover {
			background-color: $hover-color;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}
}

@mixin triangle() {
	&:before {
		content: "";
		position: absolute;
		top: -10px;
		left: 50%;
		padding: 10px;
		background: inherit;
		border: inherit;
		border-right: 0;
		border-bottom: 0;
		transform: translateX(-50%) rotate(45deg);
	}
}

@mixin tooltip() {
	display: inline;
	top: calc(100% - 10px);
	position: absolute;
	max-width: 14em;
	padding: .6em .8em;
	border-radius: .3em;
	margin: .3em 0 0 -.2em;
	background: #fffacd;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0.05em 0.2em 0.6em rgba(0, 0, 0, 0.2);
	font-size: 75%;
	right: 50%;
	transform: translateX(50%);
	white-space: nowrap;
}

@mixin break-word() {
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-all;
}

@mixin lab-button {
	border-radius: 3px;
	border: solid 1px #408dc1;
	background-color: #bfe9fc;
	font-family: Avenir;
	color: #0067ac;
	cursor: pointer;

	&:hover {
		border-width: 2px;
	}

	&:active {
		background-color: #408dc1;
		color: #fff;
	}

	&[disabled] {
		background-color: #f4f4f5;
		border: solid 1px #9fa1a3;
		color: #c0c0c0;
	}

	&.lab-button-alert {
		border: solid 1px #e70000;
		background-color: #f2dfde;
		color: #e70000;
		box-shadow: none;

		&:hover {
			border-width: 2px;
			box-shadow: none;
		}

		&[disabled] {
			background-color: #f4f4f5;
			border: solid 1px #9fa1a3;
			color: #c0c0c0;
		}
	}
}

@mixin menuOption {
	padding: 5px 10px;
	background: transparent;
	width: 100%;
	font-family: Avenir;
	color: #3e3d40;
	font-size: 15px;
	height: 32px;
	text-align: left;
	white-space: nowrap;

	&.separator {
		margin-top: 1px;
		border-top: 1px solid #c9cacb;
	}

	span {
		margin-left: 10px;
	}
}

@mixin listHover {
	background: #e3e3e3;
}

@mixin transformScale($scaleValue) {
	-webkit-transform: scale($scaleValue);
	-ms-transform: scale($scaleValue);
	-moz-transform: scale($scaleValue);
	transform: scale($scaleValue);
}

@mixin settings-content {
	padding: 0 32px;
	margin: 11px 0 32px;
	display: block;
}

@mixin settings-content-no-header {
	@include settings-content;
	margin-top: 35px;
}

@mixin settings-section {
	width: 355px;
	display: inline;
}

@mixin settings-header {
	height: 64px;
	font-size: 20px;
	color: $primary-darker;
	display: flex;
	align-items: center;
	padding: 0 31px;
	margin-top: 6px;
	border-bottom: solid 1px #cacaca;
}

@mixin settings-sub-header {
	@include settings-header;

	font-size: 18px;
	height: auto;
	margin: 0;
	padding: 23px 0;
	border-bottom: none;
}

// disable select on touch

@mixin disable-text-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
